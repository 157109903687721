/* reviewForm.module.css */

.form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.starRating {
    color: #ccc;
    font-size: 24px;
    margin-top: 5px;
    cursor: pointer;
}

.filled {
    color: gold;
}

.empty {
    color: #ccc;
}

.submitButton {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submitButton:hover {
    background-color: #0056b3;
}

.invisible {
    display: none;
}