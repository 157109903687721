.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.error {
    font-size: 14px;
    color: red;
}

.card {
    width: 500px;
    background-color: #f2f2f2;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form {
    display: block;
    margin-bottom: 16px;
}

.form label {
    display: block;
    margin-bottom: 8px;
}

.form input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.form select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* Additional styling if needed */
}
.form textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button {
    margin-top: 10px;
    width: 100%;
    padding: 8px;
    background-color: #01B399;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #e5e5e5;
}

.select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

/* Radio button styles */
.radio-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.radio-group label {
    margin-right: 10px;
}

.radio-group input[type="radio"] {
    margin-right: 5px;
}