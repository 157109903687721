.internshipList {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    max-width: 100%;
}

.internshipCard {
    background-color: #ffffff;
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 4px rgba(65, 65, 65, 0.715);
    padding: 16px;
    margin: 16px;
    min-width: 300px;
    flex: 0 0 auto;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.internshipCard img {
    width: 100%;
    max-height: 200px;
    /* object-fit: cover; */
    border-radius: 8px;
    margin-bottom: 16px;
}
.internshipCard:hover {
    box-shadow: 0 4px 8px rgba(0, 91, 66, 0.45);

}

.internshipCard h3 {
    margin-top: 0;
}

.internshipCard p {
    margin-bottom: 8px;
}